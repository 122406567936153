import { ReactNode } from 'react';
import { Link } from '../interface-adapters/navigation/router';

interface Props {
  checked: boolean;
  termsLink: string;
  onChange: (checked: boolean) => void;
  children: ReactNode;
}

export const TermsPage = ({ checked, termsLink, onChange, children }: Props): JSX.Element => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <>
      {children}
      <div className="l-row l-row--mt-80">
        <div className="c-checkbox">
          <label className="c-checkbox__label">
            <input
              type="checkbox"
              name="checkbox"
              hidden
              onChange={handleChange}
              checked={checked}
            />
            <span className="c-checkbox__icon" />
            <span className="c-checkbox__title">
              I agree with{' '}
              <Link to={termsLink}>
                <span className="c-checkbox__link">TERMS AND CONDITIONS</span>
              </Link>
            </span>
          </label>
        </div>
      </div>
    </>
  );
};
