import React, { FC, useCallback, useEffect } from 'react';

import { getCtx } from '../diContext';

export const LogUnhandledErrorsService: FC = ({ children }) => {
  const {
    logger: { error },
  } = getCtx();
  const windowErrorHandler = useCallback(
    function (e: ErrorEvent) {
      error('Unhandled error', 'Unknown', e.error);
      return false;
    },
    [error],
  );

  useEffect(() => {
    window.addEventListener('error', windowErrorHandler);

    return () => {
      window.removeEventListener('error', windowErrorHandler);
    };
  }, [windowErrorHandler]);

  return <>{children}</>;
};
