import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { useAppDispatch } from './store/hooks';
import { useHistory } from './navigation/router';

export type ActionWithHistory<P> = {
  data: P;
  history: ReturnType<typeof useHistory>;
};

export const createActionWithHistory = <P>(name: string) => {
  return createAction<ActionWithHistory<P>>(name);
};

export const useDispatchWithHistory = <P = any>() => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (action: ActionCreatorWithPayload<ActionWithHistory<P>>, data: P) => {
    dispatch(
      action({
        data,
        history,
      }),
    );
  };
};
