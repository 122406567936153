import React, { FC } from 'react';

import { ValidateOtpPage as CValidateOtpPage } from '../../components/pages/ValidateOtpPage';
import { signIn, validatePhoneOtp } from './authStore';
import { useHistory, useParams } from '../navigation/router';
import { routes } from '../../domain/routes';
import { useDispatchWithHistory } from '../actionWithHistory';

export const ValidateOtpPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const history = useHistory();
  const { phoneNumber = '' } = useParams<{ phoneNumber?: string }>();
  const sendCode = (otp: string) => {
    dispatch(validatePhoneOtp, { phone: phoneNumber, otp });
  };

  const handleResend = () => {
    dispatch(signIn, { phone: phoneNumber });
  };

  const handleBack = () => {
    history.push(routes.signIn);
  };

  return (
    <CValidateOtpPage
      onCrossClick={handleBack}
      onSendOtpClick={sendCode}
      phoneNumber={phoneNumber}
      onResendActivationCode={handleResend}
    />
  );
};
